<label class="c-app-form_label" for="usuario">
  <span class="c-app-form_descricao">{{ inputLabel }}</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="usuario"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoUsuario"
      placeholder="Selecione"
      (input)="filtrarUsuario($event)"
      (focus)="filtrarUsuario(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoUsuario
      [displayWith]="exibirDadosUsuario()">
      <mat-option>Selecione</mat-option>
      @for (usuario of usuariosFiltrados; track usuario) {
        <mat-option [value]="usuario[usuarioKey]">
          {{ formatarSelectUsuario(usuario) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
