<label class="c-app-form_label" for="codFilial">
  <span class="c-app-form_descricao">Filial</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codFilial"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoFilial"
      placeholder="Selecione"
      (input)="filtrarFilial($event)"
      (focus)="filtrarFilial(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoFilial
      [displayWith]="exibirDadosFilial()">
      <mat-option>Selecione</mat-option>
      @for (filial of filiaisFiltradas; track filial) {
        <mat-option [value]="filial?.codFilial">
          {{ formatarSelectFilial(filial) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
