import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmarAcaoDialogComponentData } from '@core/models/components/confirmarAcaoDialogComponentData';

@Component({
  selector: 'app-confirmar-acao-dialog',
  templateUrl: './confirmar-acao-dialog.component.html',
  styleUrl: './confirmar-acao-dialog.component.scss'
})
export class ConfirmarAcaoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmarAcaoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmarAcaoDialogComponentData = new ConfirmarAcaoDialogComponentData()
  ) {}
}
