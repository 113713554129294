export class PaginacaoResponseData<TRegistros> {
  constructor(
    public totalDeItens: number = 0,
    public itensPorPagina: number = 20,
    public paginaAtual: number = 1,
    public totalDePaginas: number = 0,
    public temPaginaAnterior: boolean = false,
    public temPaginaSeguinte: boolean = false,
    public registros: TRegistros[] = []
  ) {}
}
