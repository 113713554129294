<mat-expansion-panel
  #turnoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Fechamento de turno</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (validaAcessoFecharTurno$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/turno/fecharTurno']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()"
        >Fechar turno
      </a>
    }
    @if (validaAcessoPesquisaFechamento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/turno/pesquisaFechamento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()"
        >Pesquisa fechamentos</a
      >
    }
    @if (validaAcessoPesquisaFechamentoAntigos$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/turno/pesquisaFechamentoAntigo']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()"
        >Pesquisa fechamentos antigos</a
      >
    }
    @if (validaAcessoSolicitarDossie$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/turno/solicitarDossie']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()"
        >Solicitar dossiê</a
      >
    }
  </div>
</mat-expansion-panel>
