import { Component, OnDestroy } from '@angular/core';
import { TituloPaginaService } from '@core/services/titulo-pagina.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnDestroy {
  constructor(public tituloPagina: TituloPaginaService) {}
  ngOnDestroy(): void {
    this.tituloPagina.defineTitulo('');
  }
}
