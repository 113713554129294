<h1 mat-dialog-title>{{ data.titulo }}</h1>
<mat-dialog-content>
  {{ data.conteudo }}
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ data.textoCancelar }}</button>
  <button mat-button cdkFocusInitial (click)="data.confirmar()">
    {{ data.textoConfirmar }}
  </button>
</mat-dialog-actions>
