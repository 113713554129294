<label class="c-app-form_label" for="areas">
  <span class="c-app-form_descricao">Areas</span>
  <mat-form-field class="c-app-form_field">
    <mat-select
      multiple
      name="areas"
      placeholder="Selecione"
      [formControl]="control"
      (focus)="filtrarArea(); tocar()"
      (blur)="tocar()">
      <mat-form-field class="c-multi-select-area_input-pesquisa">
        <input
          matInput
          placeholder="Pesquisar"
          aria-label="Pesquisar"
          [(ngModel)]="pesquisa"
          (input)="filtrarArea($event)"
          (focus)="filtrarArea(); tocar()"
          (blur)="tocar()" />
        <button
          type="button"
          matSuffix
          mat-icon-button
          (click)="limparPesquisa()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-checkbox
        class="c-multi-select-area_selecionar-todos"
        color="primary"
        [checked]="todosSelecionados"
        [indeterminate]="algunsSelecionados"
        (change)="selecionarTodos($event.checked)">
        Todos
      </mat-checkbox>
      <mat-divider></mat-divider>
      @for (area of areasFiltrados; track $index) {
        <mat-option [value]="area.codArea">
          {{ formatarSelectArea(area) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</label>
