<mat-expansion-panel
  #limpezaConservacaoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Limpeza e conservação</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (pesquisaRespostas$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/limpezaConservacao/pesquisaRespostas']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Pesquisa respostas
      </a>
    }
    @if (responderFormulario$ | async) {
      <ng-container
        *ngIf="obterFormulariosUsuario | async as formulariosUsuario">
        @for (item of formulariosUsuario.registros; track $index) {
          <a
            matRipple
            class="c-collapse-menu-lateral_link"
            [routerLink]="[
              '/limpezaConservacao/responderFormulario',
              item.codFormulario
            ]"
            routerLinkActive="is-ativo"
            (isActiveChange)="rolarParaInicio()">
            {{ item.nomFormulario }}
          </a>
        }
      </ng-container>
    }
  </div>
</mat-expansion-panel>
