<mat-expansion-panel
  #gedExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >GED</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (arvoreEListaMestra$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/ged/PesquisaListaMestra']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Lista mestra
      </a>
    }
    @if (arvoreEListaMestra$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/ged/arvore']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Árvore
      </a>
    }
    @if (cadastrarDocumento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/ged/cadastrarDocumento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Inserir documento
      </a>
    }
  </div>
</mat-expansion-panel>
