import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { ObterFilialResponseRegistro } from '@core/models/services/filial/ObterFilialResponseRegistro';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-filial',
  templateUrl: './input-filial.component.html',
  styleUrl: './input-filial.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputFilialComponent
    }
  ]
})
export class InputFilialComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();

  control!: FormControl<number | null>;

  @Input()
  invalido: boolean = false;

  @Input({
    required: true,
    transform: paginacaoResponseDataAttribute
  })
  filiais!: PaginacaoResponseData<ObterFilialResponseRegistro>;

  filiaisFiltradas: ObterFilialResponseRegistro[] = [];

  onTouched!: () => void;

  touched: boolean = false;

  constructor(private fb: FormBuilder) {}

  formatarSelectFilial(filial: ObterFilialResponseRegistro) {
    return `${filial?.codFilial} - ${filial?.nomFilial}`;
  }

  exibirDadosFilial(): (value: unknown) => string {
    return (value: unknown) => {
      const filial = this.filiais?.registros.find(v => v?.codFilial === value);

      if (filial) {
        return this.formatarSelectFilial(filial);
      }

      return '';
    };
  }

  filtrarFilial(event?: Event) {
    if (!event) {
      this.filiaisFiltradas = this.filiais.registros;
      return;
    }

    const codFilial = (event.target as HTMLInputElement)?.value;
    this.filiaisFiltradas = this.filiais.registros.filter(v => {
      return this.formatarSelectFilial(v)
        .toLowerCase()
        .includes(codFilial.toLowerCase());
    });
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control(0);
  }

  limpar() {
    this.tocar();
    this.control.reset();
  }

  writeValue(codFilial: number | null): void {
    this.control.setValue(codFilial, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  ngOnInit(): void {
    this.filiaisFiltradas = this.filiais.registros;
    this._inicializarFormulario();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
