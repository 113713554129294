import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ContentChildren,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ObterAreaResponseRegistro } from '@core/models/services/area/obterAreaResponseRegistro';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-select-area',
  templateUrl: './multi-select-area.component.html',
  styleUrl: './multi-select-area.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiSelectAreaComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MultiSelectAreaComponent
    }
  ]
})
export class MultiSelectAreaComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSelect) matSelect!: MatSelect;
  private fb = inject(FormBuilder);
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();
  control!: FormControl<number[]>;

  @Input()
  inputLabel: string = 'Areas';

  @Input()
  areaKey: keyof ObterAreaResponseRegistro = 'codArea';

  @Input({
    transform: booleanAttribute
  })
  invalido: boolean = false;

  private _areas: PaginacaoResponseData<ObterAreaResponseRegistro> =
    new PaginacaoResponseData<ObterAreaResponseRegistro>();
  @Input({
    required: true,
    transform: paginacaoResponseDataAttribute
  })
  public set areas(v: PaginacaoResponseData<ObterAreaResponseRegistro>) {
    this._areas = v;
    this.areasFiltrados = v.registros;
  }
  public get areas(): PaginacaoResponseData<ObterAreaResponseRegistro> {
    return this._areas;
  }
  areasFiltrados: ObterAreaResponseRegistro[] = [];
  onTouched!: () => void;
  touched: boolean = false;
  todosSelecionados: boolean = false;
  algunsSelecionados: boolean = false;
  pesquisa = '';
  atualizarSelecionados() {
    this._subscription$.add(
      this.control.valueChanges.subscribe(areas => {
        this.todosSelecionados =
          !!this.areas.registros.length &&
          areas.length === this.areas.registros.length;
        this.algunsSelecionados = areas.length > 0 && !this.todosSelecionados;
      })
    );
  }
  selecionarTodos(completed: boolean) {
    this.todosSelecionados = completed;

    if (!completed) {
      this.control.reset();
      return;
    }
    this.control.setValue(this.areas.registros.map(e => e.codArea!));
  }
  limparPesquisa() {
    this.pesquisa = '';
  }
  formatarSelectArea(area: ObterAreaResponseRegistro) {
    return `${area?.codArea} - ${area?.nomArea}`;
  }
  filtrarArea(event?: Event) {
    if (!event) {
      this.areasFiltrados = this.areas.registros;
      return;
    }

    const codArea = (event.target as HTMLInputElement)?.value;
    this.areasFiltrados = this.areas.registros.filter(v => {
      return this.formatarSelectArea(v)
        .toLowerCase()
        .includes(codArea.toLowerCase());
    });
  }
  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control([], { nonNullable: true });
  }

  writeValue(valor: number[]): void {
    this.control.setValue(valor, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.setErrors(control.errors);
    this.control.updateValueAndValidity({ emitEvent: false });
    return null;
  }

  ngOnInit(): void {
    this.areasFiltrados = this.areas.registros;
    this._inicializarFormulario();
    this.atualizarSelecionados();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
    this.matSelect._handleKeydown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        return;
      }

      MatSelect.prototype._handleKeydown.call(this.matSelect, event);
    };
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
