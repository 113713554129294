import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TurnoService } from '@core/services/turno.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-campo-status-assinatura',
  templateUrl: './campo-status-assinatura.component.html',
  styleUrl: './campo-status-assinatura.component.scss'
})
export class CampoStatusAssinaturaComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  status: string = 'PENDENTE ASSINATURA';
  @Input() nomSign?: string;
  constructor(private turnoService: TurnoService) {}
  obterStatusAssinatura(): void {
    if (this.nomSign) {
      this.subscription$.add(
        this.turnoService
          .obterStatusAssinatura({ nomSign: this.nomSign })
          .subscribe(({ data }) => {
            this.status = data!.statusAssinatura!;
          })
      );
    }
  }
  ngOnInit(): void {
    this.obterStatusAssinatura();
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
