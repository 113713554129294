<mat-drawer-container class="c-menu-lateral" [hasBackdrop]="mobile">
  <mat-drawer
    class="c-menu-lateral_drawer"
    [mode]="mobile ? 'over' : 'side'"
    [opened]="true">
    <div class="c-menu-lateral_header">
      <a [routerLink]="['/']">
        <img
          src="/assets/logo.png"
          class="c-menu-lateral_logo"
          alt="Logo EBCO" />
      </a>
      <ng-lottie
        class="c-menu-lateral_animacao"
        [options]="options"></ng-lottie>
    </div>
    <div class="c-menu-lateral_options">
      <app-collapse-menu-lateral> </app-collapse-menu-lateral>
    </div>
  </mat-drawer>
  <mat-drawer-content class="c-menu-lateral_content">
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
