<app-aviso-ambiente></app-aviso-ambiente>
<div class="container">
  <div class="side-one">
    <img
      class="side-one-image"
      src="assets/img/ilustração.png"
      alt="Ilustração login"
      width="100%"
      height="100%" />
  </div>
  <div class="side-two">
    <div class="container-login">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
