<mat-expansion-panel
  #manutencaoExpansionPanel
  class="c-collapse-menu-lateral_container">
  <mat-expansion-panel-header
    matRipple
    class="c-collapse-menu-lateral_cabecalho"
    [ngClass]="{ 'is-aberto': expansionPanel?.expandedChange | async }">
    <mat-panel-title class="c-collapse-menu-lateral_titulo"
      >Manutenção</mat-panel-title
    >
  </mat-expansion-panel-header>
  <div class="c-collapse-menu-lateral_conteudo">
    @if (historicoStatusEquipamento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/historicoStatusEquipamento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Histórico status de equipamento
      </a>
    }
    @if (alterarStatusEquipamento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/alterarStatusEquipamento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Alterar status equipamento
      </a>
    }
    @if (graficoStatusEquipamento$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/graficoStatusEquipamento']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Gráfico status equipamento
      </a>
    }
    @if (graficoMovimentacaoConteineres$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/graficoStatusContainer']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Gráfico inspeções
      </a>
    }
    @if (resumoOperacoes$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/resumoOperacoes']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Resumo das operações
      </a>
    }
    @if (dashboardCliente$ | async) {
      <a
        matRipple
        class="c-collapse-menu-lateral_link"
        [routerLink]="['/manutencao/dashboardCliente']"
        routerLinkActive="is-ativo"
        (isActiveChange)="rolarParaInicio()">
        Dashboard cliente
      </a>
    }
  </div>
</mat-expansion-panel>
