import { TokenPayload } from '@core/models/services/autenticar/tokenPayload';
import { jwtDecode } from 'jwt-decode';

import { obterToken } from './obterToken';

export const obterTokenPayload = () => {
  const token = obterToken();

  if (!token) {
    return undefined;
  }

  const tokenPayload: TokenPayload = jwtDecode(token);
  tokenPayload.codClientes = JSON.parse(
    tokenPayload.codClientes as unknown as string
  );
  return tokenPayload;
};
