<label class="c-app-form_label" for="codCliente">
  <span class="c-app-form_descricao">Cliente</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codCliente"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoCliente"
      placeholder="Selecione"
      (input)="filtrarCliente($event)"
      (focus)="filtrarCliente(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoCliente
      [displayWith]="exibirDadosCliente()">
      <mat-option>Selecione</mat-option>
      @for (cliente of clientesFiltrados; track cliente) {
        <mat-option [value]="cliente?.codCliente">
          {{ formatarSelectCliente(cliente) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
