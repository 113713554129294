import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { ObterUsuarioResponseRegistro } from '@core/models/services/usuario/obterUsuarioResponseRegistro';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-usuario',
  templateUrl: './input-usuario.component.html',
  styleUrl: './input-usuario.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputUsuarioComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputUsuarioComponent
    }
  ]
})
export class InputUsuarioComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy
{
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();

  control!: FormControl<number | null>;

  @Input()
  inputLabel: string = 'Matrícula';

  @Input()
  usuarioKey: keyof ObterUsuarioResponseRegistro = 'codUsuario';

  @Input() formatarSelectUsuario = (usuario: ObterUsuarioResponseRegistro) => {
    return `${usuario.codUsuario} - ${usuario.nomNome || usuario.nomEmail}`;
  };

  @Input({
    transform: booleanAttribute
  })
  invalido: boolean = false;

  @Input({
    required: true,
    transform: paginacaoResponseDataAttribute
  })
  usuarios!: PaginacaoResponseData<ObterUsuarioResponseRegistro>;
  usuariosFiltrados: ObterUsuarioResponseRegistro[] = [];

  onTouched!: () => void;

  touched: boolean = false;

  constructor(private fb: FormBuilder) {}

  exibirDadosUsuario(): (value: unknown) => string {
    return (value: unknown) => {
      const usuario = this.usuarios?.registros.find(
        v => v?.[this.usuarioKey] === value
      );

      if (usuario) {
        return this.formatarSelectUsuario(usuario);
      }

      return '';
    };
  }

  filtrarUsuario(event?: Event) {
    if (!event) {
      this.usuariosFiltrados = this.usuarios.registros;
      return;
    }

    const nomMatricula = (event.target as HTMLInputElement)?.value;
    this.usuariosFiltrados = this.usuarios.registros.filter(v => {
      return this.formatarSelectUsuario(v)
        .toLowerCase()
        .includes(nomMatricula.toLowerCase());
    });
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control(0);
  }

  limpar() {
    this.tocar();
    this.control.reset();
  }

  writeValue(valor: number | null): void {
    this.control.setValue(valor, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.setErrors(control.errors);
    this.control.updateValueAndValidity({ emitEvent: false });
    return null;
  }

  ngOnInit(): void {
    this.usuariosFiltrados = this.usuarios.registros;
    this._inicializarFormulario();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
