<app-cabecalho></app-cabecalho>
<app-menu-lateral>
  <main class="c-app_conteudo">
    <app-aviso-ambiente></app-aviso-ambiente>
    <h1 class="c-app_titulo">{{ tituloPagina.titulo() }}</h1>
    <div class="c-app_container">
      <router-outlet></router-outlet>
    </div>
  </main>
</app-menu-lateral>
