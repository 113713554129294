@if (visivel) {
  <div class="c-aviso-ambiente_container">
    <h1>
      {{ ambiente }}
    </h1>
    <button
      class="c-aviso-ambiente_botao"
      type="button"
      mat-raised-button
      color="primary"
      (click)="fechar()">
      Fechar temporariamente
    </button>
  </div>
}
