import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EStorageKey } from '@core/enums/EStorageKey';
import { AutenticarRequestBody } from '@core/models/services/autenticar/autenticarRequestBody';
import { AutenticarResponseData } from '@core/models/services/autenticar/autenticarResponseData';
import { RedefinirRequestBody } from '@core/models/services/autenticar/redefinirRequestBody';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { obterToken } from '@core/utils/obterToken';
import { obterTokenPayload } from '@core/utils/obterTokenPayload';
import moment from 'moment';
import { Observable, tap } from 'rxjs';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AutenticarService {
  private baseURL: string = 'Autenticacao';
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}
  isAuthenticatedAndTokenValid() {
    const tokenData = this.tokenPayload();
    if (!tokenData) {
      this.sair();
      return false;
    }

    const agora = moment();
    const dataExpiracao = moment.unix(tokenData.exp);
    const expirado = agora.isSameOrAfter(dataExpiracao);
    if (expirado) {
      this.sair();
      return false;
    }

    return true;
  }
  autenticar(
    body: AutenticarRequestBody
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http
      .post<TechscanApiResponse<AutenticarResponseData>>(
        `${this.baseURL}/AutenticarUsuario`,
        body
      )
      .pipe(
        tap({
          next: ({ data }) => {
            this.storageService.armazenarLocal(EStorageKey.TOKEN, data!.token);
          }
        })
      );
  }

  enviarEmailRedefinicao(
    email: string
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http.post<TechscanApiResponse<AutenticarResponseData>>(
      `${this.baseURL}/SolicitarRedefinicaoDeSenha`,
      { email }
    );
  }
  redefinirSenha(
    body: RedefinirRequestBody
  ): Observable<TechscanApiResponse<AutenticarResponseData>> {
    return this.http.patch<TechscanApiResponse<AutenticarResponseData>>(
      `${this.baseURL}/RedefinirSenha`,
      body
    );
  }

  sair() {
    this.storageService.limparLocal();
    this.router.navigate(['/login']);
  }
  token() {
    return obterToken();
  }
  tokenPayload() {
    return obterTokenPayload();
  }
}
