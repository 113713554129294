import {
  AfterViewInit,
  booleanAttribute,
  Component,
  ContentChildren,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { ObterCargoResponseRegistro } from '@core/models/services/cargo/obterCargoResponseRegistro';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-select-cargo',
  templateUrl: './multi-select-cargo.component.html',
  styleUrl: './multi-select-cargo.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiSelectCargoComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MultiSelectCargoComponent
    }
  ]
})
export class MultiSelectCargoComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSelect) matSelect!: MatSelect;
  private fb = inject(FormBuilder);
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();
  control!: FormControl<number[]>;

  @Input()
  inputLabel: string = 'Cargos';

  @Input()
  cargoKey: keyof ObterCargoResponseRegistro = 'codCargo';

  @Input({
    transform: booleanAttribute
  })
  invalido: boolean = false;

  private _cargos: PaginacaoResponseData<ObterCargoResponseRegistro> =
    new PaginacaoResponseData<ObterCargoResponseRegistro>();
  @Input({
    required: true,
    transform: paginacaoResponseDataAttribute
  })
  public set cargos(v: PaginacaoResponseData<ObterCargoResponseRegistro>) {
    this._cargos = v;
    this.cargosFiltrados = v.registros;
  }
  public get cargos(): PaginacaoResponseData<ObterCargoResponseRegistro> {
    return this._cargos;
  }
  cargosFiltrados: ObterCargoResponseRegistro[] = [];
  onTouched!: () => void;
  touched: boolean = false;
  todosSelecionados: boolean = false;
  algunsSelecionados: boolean = false;
  pesquisa = '';
  atualizarSelecionados() {
    this._subscription$.add(
      this.control.valueChanges.subscribe(cargos => {
        this.todosSelecionados =
          !!this.cargos.registros.length &&
          cargos.length === this.cargos.registros.length;
        this.algunsSelecionados = cargos.length > 0 && !this.todosSelecionados;
      })
    );
  }
  selecionarTodos(completed: boolean) {
    this.todosSelecionados = completed;

    if (!completed) {
      this.control.reset();
      return;
    }
    this.control.setValue(this.cargos.registros.map(e => e.codCargo!));
  }
  limparPesquisa() {
    this.pesquisa = '';
  }
  formatarSelectCargo(cargo: ObterCargoResponseRegistro) {
    return `${cargo?.codCargo} - ${cargo?.nomCargo}`;
  }
  filtrarCargo(event?: Event) {
    if (!event) {
      this.cargosFiltrados = this.cargos.registros;
      return;
    }

    const codCargo = (event.target as HTMLInputElement)?.value;
    this.cargosFiltrados = this.cargos.registros.filter(v => {
      return this.formatarSelectCargo(v)
        .toLowerCase()
        .includes(codCargo.toLowerCase());
    });
  }
  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control([], { nonNullable: true });
  }

  writeValue(valor: number[]): void {
    this.control.setValue(valor, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.setErrors(control.errors);
    this.control.updateValueAndValidity({ emitEvent: false });
    return null;
  }

  ngOnInit(): void {
    this.cargosFiltrados = this.cargos.registros;
    this._inicializarFormulario();
    this.atualizarSelecionados();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
    this.matSelect._handleKeydown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        return;
      }

      MatSelect.prototype._handleKeydown.call(this.matSelect, event);
    };
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
