<label class="c-app-form_label" for="codFuncionalidade">
  <span class="c-app-form_descricao">Funcionalidade</span>
  <mat-form-field [class]="'{{classe}} c-app-form_field'">
    <input
      type="text"
      matInput
      name="codFuncionalidade"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoFuncionalidade"
      placeholder="Selecione"
      (input)="filtrarFuncionalidade($event)"
      (focus)="filtrarFuncionalidade(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoFuncionalidade
      [displayWith]="exibirDadosFuncionalidade()">
      <mat-option>Selecione</mat-option>
      @for (funcionalidade of funcionalidadesFiltrados; track funcionalidade) {
        <mat-option [value]="funcionalidade?.codFuncionalidade">
          {{ formatarSelectFuncionalidade(funcionalidade) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
