import { booleanAttribute, Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { forkJoin, Observable, Subscription, tap } from 'rxjs';

export type InputArquivoValor = { nome: string; base64: string };

@Component({
  selector: 'app-input-arquivo',
  templateUrl: './input-arquivo.component.html',
  styleUrl: './input-arquivo.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputArquivoComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputArquivoComponent
    }
  ]
})
export class InputArquivoComponent
  implements OnDestroy, ControlValueAccessor, Validator
{
  private subscription$ = new Subscription();
  @Input()
  arquivosAceitos = '';
  @Input({ transform: booleanAttribute })
  multiplo = false;
  onTouched!: () => void;
  onChange!: () => void;
  touched: boolean = false;
  isDisabled = false;
  valor: InputArquivoValor[] = [];

  arquivoSelecionado(event: Event) {
    const arquivos = Array.from(
      (event.target as unknown as { files: FileList }).files
    );
    const files$: Observable<InputArquivoValor>[] = [];
    for (const item of arquivos) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      files$.push(
        new Observable<InputArquivoValor>(subscriber => {
          reader.onload = function () {
            subscriber.next({
              nome: item.name,
              base64: (reader.result as string).split(',')[1]
            });
            subscriber.complete();
          };
        })
      );
    }
    this.subscription$.add(
      forkJoin(files$)
        .pipe(
          tap(v => {
            this.valor = v;
          })
        )
        .subscribe(this.onChange)
    );
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private valido(valor: unknown) {
    if (
      !valor ||
      (Array.isArray(valor) && valor.every(v => v?.nome && v?.base64))
    ) {
      return true;
    }

    return false;
  }

  writeValue(valor: InputArquivoValor[]): void {
    if (!this.valido(valor)) {
      this.valor = [];
      return;
    }

    this.valor = valor;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const valor = control.value;
    if (!this.valido(valor)) {
      return {
        valorInvalido: true
      };
    }

    return null;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
