import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-nao-encontrado',
  templateUrl: './nao-encontrado.component.html',
  styleUrl: './nao-encontrado.component.scss'
})
export class NaoEncontradoComponent {
  location = inject(Location);
  options: AnimationOptions = {
    path: `/assets/animations/notFound.json`
  };
}
