import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment';
import moment from 'moment';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-aviso-ambiente',
  templateUrl: './aviso-ambiente.component.html',
  styleUrl: './aviso-ambiente.component.scss'
})
export class AvisoAmbienteComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  protected get ambiente() {
    const ambientes: Record<string, string> = {
      main: 'PRODUÇÃO',
      hom: 'HOMOLOGAÇÃO',
      qa: 'QA',
      dev: 'DESENVOLVIMENTO',
      local: 'LOCAL'
    };

    return ambientes[environment.environment] || '';
  }
  protected visivel = false;
  private alternarVisualizacao(visivel: boolean = !this.visivel) {
    if (environment.environment === 'main') {
      this.visivel = false;
      return;
    }

    this.visivel = visivel;
  }
  protected fechar() {
    this.alternarVisualizacao(false);
    this.subscription$.add(
      timer(moment().add(2, 'minutes').toDate()).subscribe(() => {
        this.alternarVisualizacao(true);
      })
    );
  }
  ngOnInit(): void {
    this.alternarVisualizacao();
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
