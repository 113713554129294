import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ObterFormulariosRequestBody } from '@core/models/services/limpezaConservacao/obterFormulariosRequestBody';
import { ObterFormulariosResponseData } from '@core/models/services/limpezaConservacao/obterFormulariosResponseData';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';
import { LimpezaConservacaoService } from '@core/services/limpeza-conservacao.service';
import { map, Subscription } from 'rxjs';

@Component({
  selector: 'app-limpeza-conservacao-expansion',
  templateUrl: './limpeza-conservacao-expansion.component.html',
  styleUrl: './limpeza-conservacao-expansion.component.scss'
})
export class LimpezaConservacaoExpansionComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild('limpezaConservacaoExpansionPanel')
  expansionPanel?: MatExpansionPanel;
  private subscription$ = new Subscription();
  private limpezaConservacaoService = inject(LimpezaConservacaoService);
  private gestaoAcessoService = inject(GestaoAcessoService);
  pesquisaRespostas$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'pesquisaRespostas'
  });
  responderFormulario$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'responderFormulario'
  });
  private router = inject(Router);
  protected obterFormulariosUsuario = this.limpezaConservacaoService
    .obterFormularios({
      paginar: false
    } as ObterFormulariosRequestBody)
    .pipe(
      map(({ data }) => {
        if (!data) {
          return new PaginacaoResponseData<ObterFormulariosResponseData>();
        }

        return data;
      })
    );
  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }
  abrir() {
    const ativo = this.router.isActive('/radioProtecao', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }
  ngAfterViewInit(): void {
    this.abrir();
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
