import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { ObterMedidorResponseRegistro } from '@core/models/services/medidor/obterMedidorResponseRegistro';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-medidor',
  templateUrl: './input-medidor.component.html',
  styleUrl: './input-medidor.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputMedidorComponent
    }
  ]
})
export class InputMedidorComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();

  @Input() objetoComoValor = false;

  control!: FormControl<number | null>;

  @Input()
  invalido: boolean = false;

  @Input({ required: true })
  medidores!: PaginacaoResponseData<ObterMedidorResponseRegistro>;

  medidoresFiltrados: ObterMedidorResponseRegistro[] = [];

  onTouched!: () => void;

  touched: boolean = false;

  constructor(private fb: FormBuilder) {}

  formatarSelectMedidor(medidor: ObterMedidorResponseRegistro) {
    return `${medidor?.codRpMedidor} - ${medidor?.nomMedidor}`;
  }

  exibirDadosMedidor(): (value: unknown) => string {
    return (value: unknown) => {
      const medidor = this.medidores?.registros.find(v => {
        if (this.objetoComoValor) {
          return (
            v?.codRpMedidor ===
            (value as ObterMedidorResponseRegistro)?.codRpMedidor
          );
        }

        return v?.codRpMedidor === value;
      });

      if (medidor) {
        return this.formatarSelectMedidor(medidor);
      }

      return '';
    };
  }

  filtrarMedidor(event?: Event) {
    if (!event) {
      this.medidoresFiltrados = this.medidores.registros;
      return;
    }

    const codMedidor = (event.target as HTMLInputElement)?.value;
    this.medidoresFiltrados = this.medidores.registros.filter(v => {
      return this.formatarSelectMedidor(v)
        .toLowerCase()
        .includes(codMedidor.toLowerCase());
    });
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control(0);
  }

  limpar() {
    this.tocar();
    this.control.reset();
  }

  writeValue(valor: number | null): void {
    this.control.setValue(valor, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  ngOnInit(): void {
    this.medidoresFiltrados = this.medidores.registros;
    this._inicializarFormulario();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
