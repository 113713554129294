import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Base64 } from '@core/models/base64';
import { ObterDocumentoLimpezaConservacaoRespostaFormularioRequestBody } from '@core/models/services/limpezaConservacao/obterDocumentoLimpezaConservacaoRespostaFormularioRequestBody';
import { ObterFormulariosRequestBody } from '@core/models/services/limpezaConservacao/obterFormulariosRequestBody';
import { ObterFormulariosResponseData } from '@core/models/services/limpezaConservacao/obterFormulariosResponseData';
import { ObterPerguntasRequestBody } from '@core/models/services/limpezaConservacao/obterPerguntasRequestBody';
import { ObterPerguntasResponseData } from '@core/models/services/limpezaConservacao/obterPerguntasResponseData';
import { ObterRespostasRequestBody } from '@core/models/services/limpezaConservacao/obterRespostasRequestBody';
import { ObterRespostasResponseData } from '@core/models/services/limpezaConservacao/obterRespostasResponseData';
import { ResponderFormularioRequestBody } from '@core/models/services/limpezaConservacao/responderFormularioRequestBody';
import { RevisarFormularioRequestBody } from '@core/models/services/limpezaConservacao/revisarFormularioRequestBody';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { ToastrService } from 'ngx-toastr';
import { first, Observable, of, switchMap } from 'rxjs';

import { AutenticarService } from './autenticar.service';
import { GestaoAcessoService } from './gestao-acesso.service';

@Injectable({
  providedIn: 'root'
})
export class LimpezaConservacaoService {
  private baseURL: string = 'LimpezaConservacao';
  private autenticarService = inject(AutenticarService);
  private gestaoAcessoService = inject(GestaoAcessoService);
  private httpClient = inject(HttpClient);
  private _gestaoAcessoService = inject(GestaoAcessoService);
  private _toastrService = inject(ToastrService);
  obterFormularios(
    body: ObterFormulariosRequestBody
  ): Observable<
    TechscanApiResponse<PaginacaoResponseData<ObterFormulariosResponseData>>
  > {
    return this.gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'visualizarTodosClientes',
        permissao: 'HABILITADO'
      })
      .pipe(
        first(),
        switchMap(habilitado => {
          if (
            !habilitado &&
            (!body?.codCliente || body.codCliente.length === 0)
          ) {
            body.codCliente =
              this.autenticarService.tokenPayload()?.codClientes;
          }
          return this.httpClient.post<
            TechscanApiResponse<
              PaginacaoResponseData<ObterFormulariosResponseData>
            >
          >(`${this.baseURL}/ObterFormularios`, body);
        })
      );
  }
  obterPerguntas(
    body: ObterPerguntasRequestBody
  ): Observable<
    TechscanApiResponse<PaginacaoResponseData<ObterPerguntasResponseData>>
  > {
    return this.httpClient.post<
      TechscanApiResponse<PaginacaoResponseData<ObterPerguntasResponseData>>
    >(`${this.baseURL}/ObterPerguntas`, body);
  }
  obterRespostas(
    body: ObterRespostasRequestBody
  ): Observable<
    TechscanApiResponse<PaginacaoResponseData<ObterRespostasResponseData>>
  > {
    return this.gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'visualizarTodosClientes',
        permissao: 'HABILITADO'
      })
      .pipe(
        first(),
        switchMap(habilitado => {
          if (
            !habilitado &&
            (!body?.codCliente || body.codCliente.length === 0)
          ) {
            body.codCliente =
              this.autenticarService.tokenPayload()?.codClientes;
          }
          return this.httpClient.post<
            TechscanApiResponse<
              PaginacaoResponseData<ObterRespostasResponseData>
            >
          >(`${this.baseURL}/ObterRespostas`, body);
        })
      );
  }
  responderFormulario(
    body: ResponderFormularioRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'responderFormulario',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/ResponderFormulario`,
            body
          );
        })
      );
  }
  revisarFormulario(
    body: RevisarFormularioRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'revisarResposta',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/RevisarFormulario`,
            body
          );
        })
      );
  }
  obterDocumentoLimpezaConservacaoRespostaFormulario(
    body: ObterDocumentoLimpezaConservacaoRespostaFormularioRequestBody
  ): Observable<TechscanApiResponse<Base64>> {
    return this.httpClient.post<TechscanApiResponse<Base64>>(
      `${this.baseURL}/ObterDocumentoLimpezaConservacaoRespostaFormulario`,
      body
    );
  }
}
