import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { AssinarTurnoRequestBody } from '@core/models/services/turno/assinarTurnoRequestBody';
import { EditarTurnoRequestBody } from '@core/models/services/turno/editarTurnoRequestBody';
import { ExcluirTurnoRequestBody } from '@core/models/services/turno/excluirTurnoRequestBody';
import { FecharTurnoRequestBody } from '@core/models/services/turno/fecharTurnoRequestBody';
import { FecharTurnoResponseData } from '@core/models/services/turno/fecharTurnoResponseData';
import { ObterDocumentoAssinaturaRequestBody } from '@core/models/services/turno/obterDocumentoAssinaturaRequestBody';
import { ObterDocumentoAssinaturaResponseData } from '@core/models/services/turno/obterDocumentoAssinaturaResponseData';
import { ObterProtocoloAssinaturaRequestBody } from '@core/models/services/turno/obterProtocoloAssinaturaRequestBody';
import { ObterProtocoloAssinaturaResponseData } from '@core/models/services/turno/obterProtocoloAssinaturaResponseData';
import { ObterRelatorioFechamentoTurnoRequestBody } from '@core/models/services/turno/obterRelatorioFechamentoTurnoRequestBody';
import { ObterRelatorioFechamentoTurnoResponseRegistro } from '@core/models/services/turno/obterRelatorioFechamentoTurnoResponseRegistro';
import { ObterStatusAssinaturaRequestBody } from '@core/models/services/turno/obterStatusAssinaturaRequestBody';
import { ObterStatusAssinaturaResponseData } from '@core/models/services/turno/obterStatusAssinaturaResponseData';
import { ObterTurnoRequestBody } from '@core/models/services/turno/obterTurnoRequestBody';
import { ObterTurnoResponseRegistro } from '@core/models/services/turno/obterTurnoResponseRegistro';
import { SolicitarDossieRequestBody } from '@core/models/services/turno/solicitarDossieRequestBody';
import { ToastrService } from 'ngx-toastr';
import { first, Observable, of, switchMap } from 'rxjs';

import { AutenticarService } from './autenticar.service';
import { GestaoAcessoService } from './gestao-acesso.service';

@Injectable({
  providedIn: 'root'
})
export class TurnoService {
  private baseURL: string = 'Turno';
  private httpClient = inject(HttpClient);
  private autenticarService = inject(AutenticarService);
  private _gestaoAcessoService = inject(GestaoAcessoService);
  private _toastrService = inject(ToastrService);

  fecharTurno(
    body: FecharTurnoRequestBody
  ): Observable<TechscanApiResponse<FecharTurnoResponseData>> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'fecharTurno',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse<FecharTurnoResponseData>());
          }
          return this.httpClient.post<
            TechscanApiResponse<FecharTurnoResponseData>
          >(`${this.baseURL}/FecharTurno`, body);
        })
      );
  }
  editarTurno(body: EditarTurnoRequestBody): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'editarTurno',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.put<TechscanApiResponse>(
            `${this.baseURL}/EditarTurno`,
            body
          );
        })
      );
  }
  excluirTurno(body: ExcluirTurnoRequestBody): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'excluirFechamento',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.delete<TechscanApiResponse>(
            `${this.baseURL}/ExcluirTurno`,
            { body }
          );
        })
      );
  }
  assinarTurno(body: AssinarTurnoRequestBody): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'enviarTurnoParaAssinatura',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/AssinarTurno`,
            body
          );
        })
      );
  }
  obterTurno(
    body: ObterTurnoRequestBody
  ): Observable<
    TechscanApiResponse<PaginacaoResponseData<ObterTurnoResponseRegistro>>
  > {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'visualizarTodosClientes',
        permissao: 'HABILITADO'
      })
      .pipe(
        first(),
        switchMap(habilitado => {
          if (
            !habilitado &&
            (!body?.codCliente || body.codCliente.length === 0)
          ) {
            body.codCliente =
              this.autenticarService.tokenPayload()?.codClientes;
          }
          return this.httpClient.post<
            TechscanApiResponse<
              PaginacaoResponseData<ObterTurnoResponseRegistro>
            >
          >(`${this.baseURL}/ObterTurno`, body);
        })
      );
  }

  obterRelatorioFechamentoTurno(
    body: ObterRelatorioFechamentoTurnoRequestBody
  ): Observable<
    TechscanApiResponse<
      PaginacaoResponseData<ObterRelatorioFechamentoTurnoResponseRegistro>
    >
  > {
    return this.httpClient.post<
      TechscanApiResponse<
        PaginacaoResponseData<ObterRelatorioFechamentoTurnoResponseRegistro>
      >
    >(`${this.baseURL}/ObterRelatorioFechamentoTurno`, body);
  }
  obterStatusAssinatura(
    body: ObterStatusAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterStatusAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterStatusAssinaturaResponseData>
    >(`${this.baseURL}/ObterStatusAssinatura`, body);
  }
  obterProtocoloAssinatura(
    body: ObterProtocoloAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterProtocoloAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterProtocoloAssinaturaResponseData>
    >(`${this.baseURL}/ObterProtocoloAssinatura`, body);
  }
  obterDocumentoAssinatura(
    body: ObterDocumentoAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterDocumentoAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterDocumentoAssinaturaResponseData>
    >(`${this.baseURL}/ObterDocumentoAssinatura`, body);
  }

  solicitarDossie(
    body: SolicitarDossieRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'solicitarDossie',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/SolicitarDossie`,
            body
          );
        })
      );
  }
}
