import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule
} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { APP_DATE_FORMATS } from '@core/constants/appDateFormats';
import { AppPaginatorIntl } from '@core/utils/appPaginatorIntl';
import { lottiePlayerFactory } from '@core/utils/lottiePlayerFactory';
import { InputContainerComponent } from 'app/modules/login/components/input-container/input-container.component';
import { InputValidacaoComponent } from 'app/modules/login/components/input-validacao/input-validacao.component';
import { TituloComponent } from 'app/modules/login/components/titulo/titulo.component';
import { LayoutComponent } from 'app/modules/login/pages/layout/layout.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { AvisoAmbienteComponent } from './components/aviso-ambiente/aviso-ambiente.component';
import { ButtonComponent } from './components/button/button.component';
import { CabecalhoComponent } from './components/cabecalho/cabecalho.component';
import { CampoStatusAssinaturaComponent } from './components/campo-status-assinatura/campo-status-assinatura.component';
import { CampoStatusAssinaturaProcedimentoComponent } from './components/campo-status-assinatura-procedimento/campo-status-assinatura-procedimento.component';
import { CollapseMenuLateralComponent } from './components/collapse-menu-lateral/collapse-menu-lateral.component';
import { ConfirmarAcaoDialogComponent } from './components/confirmar-acao-dialog/confirmar-acao-dialog.component';
import { GedExpansionComponent } from './components/ged-expansion/ged-expansion.component';
import { GestaoAcessoExpansionComponent } from './components/gestao-acesso-expansion/gestao-acesso-expansion.component';
import { UsuarioExpansionComponent } from './components/usuario-expansion/usuario-expansion.component';
import { InputComponent } from './components/input/input.component';
import { InputAreaComponent } from './components/input-area/input-area.component';
import { InputArquivoComponent } from './components/input-arquivo/input-arquivo.component';
import { InputCargoComponent } from './components/input-cargo/input-cargo.component';
import { InputClienteComponent } from './components/input-cliente/input-cliente.component';
import { InputFilialComponent } from './components/input-filial/input-filial.component';
import { InputFuncionalidadeComponent } from './components/input-funcionalidade/input-funcionalidade.component';
import { InputMedidorComponent } from './components/input-medidor/input-medidor.component';
import { InputPerfilComponent } from './components/input-perfil/input-perfil.component';
import { InputUsuarioComponent } from './components/input-usuario/input-usuario.component';
import { LimpezaConservacaoExpansionComponent } from './components/limpeza-conservacao-expansion/limpeza-conservacao-expansion.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MainComponent } from './components/main/main.component';
import { ManutencaoExpansionComponent } from './components/manutencao-expansion/manutencao-expansion.component';
import { MenuLateralComponent } from './components/menu-lateral/menu-lateral.component';
import { MultiSelectAreaComponent } from './components/multi-select-area/multi-select-area.component';
import { MultiSelectCargoComponent } from './components/multi-select-cargo/multi-select-cargo.component';
import { MultiSelectClienteComponent } from './components/multi-select-cliente/multi-select-cliente.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { RadioProtecaoExpansionComponent } from './components/radio-protecao-expansion/radio-protecao-expansion.component';
import { TurnoExpansionComponent } from './components/turno-expansion/turno-expansion.component';
import { HomeComponent } from './pages/home/home.component';
import { NaoEncontradoComponent } from './pages/nao-encontrado/nao-encontrado.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    InputComponent,
    ButtonComponent,
    MenuLateralComponent,
    CabecalhoComponent,
    CollapseMenuLateralComponent,
    PageContainerComponent,
    CampoStatusAssinaturaComponent,
    CampoStatusAssinaturaProcedimentoComponent,
    LoaderComponent,
    MainComponent,
    LayoutComponent,
    TituloComponent,
    InputValidacaoComponent,
    InputContainerComponent,
    TurnoExpansionComponent,
    RadioProtecaoExpansionComponent,
    ConfirmarAcaoDialogComponent,
    InputClienteComponent,
    InputPerfilComponent,
    InputUsuarioComponent,
    InputCargoComponent,
    InputAreaComponent,
    InputFilialComponent,
    InputMedidorComponent,
    GedExpansionComponent,
    GestaoAcessoExpansionComponent,
    UsuarioExpansionComponent,
    ManutencaoExpansionComponent,
    InputArquivoComponent,
    InputFuncionalidadeComponent,
    MultiSelectClienteComponent,
    MultiSelectCargoComponent,
    MultiSelectAreaComponent,
    NaoEncontradoComponent,
    HomeComponent,
    LimpezaConservacaoExpansionComponent,
    AvisoAmbienteComponent
  ],
  imports: [
    MatTooltipModule,
    CommonModule,
    MatInputModule,
    MatSidenavModule,
    MatIconModule,
    MatRippleModule,
    MatToolbarModule,
    MatExpansionModule,
    RouterModule,
    MatMenuModule,
    LottieModule.forRoot({ player: lottiePlayerFactory }),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatDividerModule,
    MatAutocompleteModule,
    LayoutModule,
    MatTableModule,
    MatDialogModule,
    OverlayModule,
    MatPaginatorModule,
    MatTreeModule,
    MatCheckboxModule,
    MatCardModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      extendedTimeOut: 5000,
      progressBar: true,
      closeButton: true,
      tapToDismiss: false
    })
  ],
  exports: [
    InputComponent,
    ButtonComponent,
    MenuLateralComponent,
    CabecalhoComponent,
    CampoStatusAssinaturaComponent,
    CampoStatusAssinaturaProcedimentoComponent,
    MainComponent,
    LoaderComponent,
    MatInputModule,
    MatTableModule,
    MatSidenavModule,
    MatIconModule,
    MatRippleModule,
    MatToolbarModule,
    MatExpansionModule,
    RouterModule,
    MatMenuModule,
    LottieModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSelectModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatDividerModule,
    MatAutocompleteModule,
    LayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    OverlayModule,
    ToastrModule,
    LayoutComponent,
    TituloComponent,
    InputValidacaoComponent,
    InputContainerComponent,
    InputClienteComponent,
    InputFilialComponent,
    InputPerfilComponent,
    InputUsuarioComponent,
    InputMedidorComponent,
    NgApexchartsModule,
    InputFuncionalidadeComponent,
    InputArquivoComponent,
    InputArquivoComponent,
    MatTreeModule,
    InputCargoComponent,
    InputAreaComponent,
    MultiSelectClienteComponent,
    MultiSelectCargoComponent,
    MultiSelectAreaComponent,
    MatCardModule,
    AvisoAmbienteComponent
  ],
  providers: [
    provideNgxMask({ decimalMarker: ',' }),
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    },
    { provide: MatPaginatorIntl, useClass: AppPaginatorIntl }
  ]
})
export class SharedModule {}
