import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';
import { TituloPaginaService } from '@core/services/titulo-pagina.service';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscription$ = new Subscription();
  private tituloPaginaService = inject(TituloPaginaService);
  private gestaoAcessoService = inject(GestaoAcessoService);
  private router = inject(Router);
  options: AnimationOptions = {
    path: `/assets/animations/home.json`
  };
  private _redirecionar() {
    this.subscription$.add(
      this.gestaoAcessoService
        .validaAcessos({ key: 'nomTag', value: 'resumoOperacoes' })
        .subscribe(habilitado => {
          if (!habilitado) {
            return;
          }

          this.router.navigate(['/manutencao/resumoOperacoes']);
        })
    );
  }
  ngOnInit(): void {
    this.tituloPaginaService.defineTitulo('');
    this._redirecionar();
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
