import { Injectable, OnDestroy } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ChartType } from 'ng-apexcharts';
import { BehaviorSubject, map, merge, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuLateralService implements OnDestroy {
  private subscriptions$ = new Subscription();
  private menuAbertoSubject = new BehaviorSubject<boolean>(true);
  menuAberto$: Observable<boolean> = this.menuAbertoSubject.asObservable();
  private _drawer?: MatDrawer;
  get drawer(): MatDrawer | undefined {
    return this._drawer;
  }
  set drawer(v: MatDrawer | undefined) {
    this._drawer = v;
    this.registrarMenuAberto();
    this.manipularGraficos();
  }
  private registrarMenuAberto(): void {
    if (!this.drawer) {
      return;
    }

    this.subscriptions$.add(
      merge(
        this.drawer.openedStart.pipe(map(() => true)),
        this.drawer.closedStart.pipe(map(() => false))
      ).subscribe(v => {
        this.menuAbertoSubject.next(v);
      })
    );
  }
  private manipularGraficos() {
    window.Apex = {
      ...window.Apex,
      chart: {
        ...window.Apex.chart,
        type: undefined as unknown as ChartType,
        events: {
          ...window.Apex.chart?.events,
          mounted: chart => {
            this.menuAberto$.subscribe(() => {
              chart.windowResizeHandler();
            });
          }
        }
      }
    };
  }
  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
