import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

@Component({
  selector: 'app-manutencao-expansion',
  templateUrl: './manutencao-expansion.component.html',
  styleUrl: './manutencao-expansion.component.scss'
})
export class ManutencaoExpansionComponent implements AfterViewInit {
  @ViewChild('manutencaoExpansionPanel') expansionPanel?: MatExpansionPanel;
  gestaoAcessoService = inject(GestaoAcessoService);
  alterarStatusEquipamento$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'alterarStatusEquipamento'
  });
  graficoMovimentacaoConteineres$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'graficoMovimentacaoConteineres'
  });
  graficoStatusEquipamento$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'graficoStatusEquipamento'
  });
  historicoStatusEquipamento$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'historicoStatusEquipamento'
  });
  resumoOperacoes$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'resumoOperacoes'
  });
  dashboardCliente$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'dashboardCliente'
  });
  constructor(private router: Router) {}

  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  abrir() {
    const ativo = this.router.isActive('/manutencao', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }

  ngAfterViewInit(): void {
    this.abrir();
  }
}
