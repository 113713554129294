import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { ObterPerfilResponseRegistro } from '@core/models/services/perfil/obterPerfilResponseRegistro';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-perfil',
  templateUrl: './input-perfil.component.html',
  styleUrl: './input-perfil.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPerfilComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputPerfilComponent
    }
  ]
})
export class InputPerfilComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy
{
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();

  control!: FormControl<number | null>;

  @Input()
  perfilKey: keyof ObterPerfilResponseRegistro = 'codPerfil';

  @Input()
  invalido: boolean = false;

  @Input({ required: true, transform: paginacaoResponseDataAttribute })
  perfis!: PaginacaoResponseData<ObterPerfilResponseRegistro>;

  perfisFiltrados: ObterPerfilResponseRegistro[] = [];

  onTouched!: () => void;

  touched: boolean = false;

  constructor(private fb: FormBuilder) {}

  formatarSelectPerfil(Perfil: ObterPerfilResponseRegistro) {
    return `${Perfil?.codPerfil} - ${Perfil?.nomPerfil}`;
  }

  exibirDadosPerfil(): (value: unknown) => string {
    return (value: unknown) => {
      const Perfil = this.perfis?.registros.find(
        v => v?.[this.perfilKey] === value
      );

      if (Perfil) {
        return this.formatarSelectPerfil(Perfil);
      }

      return '';
    };
  }

  filtrarPerfil(event?: Event) {
    if (!event) {
      this.perfisFiltrados = this.perfis.registros;
      return;
    }

    const nomMatricula = (event.target as HTMLInputElement)?.value;
    this.perfisFiltrados = this.perfis.registros.filter(v => {
      return this.formatarSelectPerfil(v)
        .toLowerCase()
        .includes(nomMatricula.toLowerCase());
    });
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control(0);
  }

  limpar() {
    this.tocar();
    this.control.reset();
  }

  writeValue(valor: number | null): void {
    this.control.setValue(valor, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control.setErrors(control.errors);
    this.control.updateValueAndValidity({ emitEvent: false });
    return null;
  }

  ngOnInit(): void {
    this.perfisFiltrados = this.perfis.registros;
    this._inicializarFormulario();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
