<label class="c-app-form_label" for="codPerfil">
  <span class="c-app-form_descricao">Perfil</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codPerfil"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoPerfil"
      placeholder="Selecione"
      (input)="filtrarPerfil($event)"
      (focus)="filtrarPerfil(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoPerfil
      [displayWith]="exibirDadosPerfil()">
      <mat-option>Selecione</mat-option>
      @for (perfil of perfisFiltrados; track perfil) {
        <mat-option [value]="perfil?.codPerfil">
          {{ formatarSelectPerfil(perfil) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
