import { EStorageKey } from '@core/enums/EStorageKey';

export const obterToken = () => {
  const token = localStorage.getItem(EStorageKey.TOKEN);
  if (!token) {
    return undefined;
  }

  return JSON.parse(token);
};
