import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { GestaoAcessoService } from '@core/services/gestao-acesso.service';

@Component({
  selector: 'app-turno-expansion',
  templateUrl: './turno-expansion.component.html',
  styleUrl: './turno-expansion.component.scss'
})
export class TurnoExpansionComponent implements AfterViewInit {
  @ViewChild('turnoExpansionPanel') expansionPanel?: MatExpansionPanel;
  gestaoAcessoService = inject(GestaoAcessoService);

  validaAcessoFecharTurno$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'fecharTurno'
  });
  validaAcessoPesquisaFechamento$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'pesquisaFechamentos'
  });
  validaAcessoPesquisaFechamentoAntigos$ =
    this.gestaoAcessoService.validaAcessos({
      key: 'nomTag',
      value: 'pesquisaFechamentosAntigos'
    });
  validaAcessoSolicitarDossie$ = this.gestaoAcessoService.validaAcessos({
    key: 'nomTag',
    value: 'solicitarDossie'
  });

  constructor(private router: Router) {}

  rolarParaInicio() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  abrir() {
    const ativo = this.router.isActive('/turno', {
      fragment: 'ignored',
      matrixParams: 'ignored',
      paths: 'subset',
      queryParams: 'ignored'
    });
    if (ativo) {
      this.expansionPanel?.open();
    }
  }

  ngAfterViewInit(): void {
    this.abrir();
  }
}
