<label class="c-app-form_label" for="cargos">
  <span class="c-app-form_descricao">Cargos</span>
  <mat-form-field class="c-app-form_field">
    <mat-select
      multiple
      name="cargos"
      placeholder="Selecione"
      [formControl]="control"
      (focus)="filtrarCargo(); tocar()"
      (blur)="tocar()">
      <mat-form-field class="c-multi-select-cargo_input-pesquisa">
        <input
          matInput
          placeholder="Pesquisar"
          aria-label="Pesquisar"
          [(ngModel)]="pesquisa"
          (input)="filtrarCargo($event)"
          (focus)="filtrarCargo(); tocar()"
          (blur)="tocar()" />
        <button
          type="button"
          matSuffix
          mat-icon-button
          (click)="limparPesquisa()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-checkbox
        class="c-multi-select-cargo_selecionar-todos"
        color="primary"
        [checked]="todosSelecionados"
        [indeterminate]="algunsSelecionados"
        (change)="selecionarTodos($event.checked)">
        Todos
      </mat-checkbox>
      <mat-divider></mat-divider>
      @for (cargo of cargosFiltrados; track $index) {
        <mat-option [value]="cargo.codCargo">
          {{ formatarSelectCargo(cargo) }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
</label>
