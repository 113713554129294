import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { ObterCargoResponseRegistro as ObterCargoResponseRegistro } from '@core/models/services/cargo/obterCargoResponseRegistro';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { paginacaoResponseDataAttribute } from '@core/utils/paginacaoResponseDataAttribute';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-cargo',
  templateUrl: './input-cargo.component.html',
  styleUrl: './input-cargo.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputCargoComponent
    }
  ]
})
export class InputCargoComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @ContentChildren(MatError) errors!: QueryList<MatError>;

  @ViewChild(MatFormField) formField!: MatFormField;

  private _subscription$: Subscription = new Subscription();

  control!: FormControl<number | null>;

  @Input()
  invalido: boolean = false;

  @Input({
    required: true,
    transform: paginacaoResponseDataAttribute
  })
  cargos!: PaginacaoResponseData<ObterCargoResponseRegistro>;

  cargosFiltrados: ObterCargoResponseRegistro[] = [];

  onTouched!: () => void;

  touched: boolean = false;

  constructor(private fb: FormBuilder) {}

  formatarSelectCargo(cargo: ObterCargoResponseRegistro) {
    return `${cargo?.codCargo} - ${cargo?.nomCargo}`;
  }

  exibirDadosCargo(): (value: unknown) => string {
    return (value: unknown) => {
      const cargo = this.cargos?.registros.find(v => v?.codCargo === value);

      if (cargo) {
        return this.formatarSelectCargo(cargo);
      }

      return '';
    };
  }

  filtrarCargo(event?: Event) {
    if (!event) {
      this.cargosFiltrados = this.cargos.registros;
      return;
    }

    const codCargo = (event.target as HTMLInputElement)?.value;
    this.cargosFiltrados = this.cargos.registros.filter(v => {
      return this.formatarSelectCargo(v)
        .toLowerCase()
        .includes(codCargo.toLowerCase());
    });
  }

  tocar() {
    if (!this.touched && this.onTouched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _inicializarFormulario() {
    this.control = this.fb.control(0);
  }

  limpar() {
    this.tocar();
    this.control.reset();
  }

  writeValue(codCargo: number | null): void {
    this.control.setValue(codCargo, { emitEvent: false });
  }

  registerOnChange(fn: () => void): void {
    this._subscription$.add(this.control.valueChanges.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
      return;
    }

    this.control.enable();
  }

  ngOnInit(): void {
    this.cargosFiltrados = this.cargos.registros;
    this._inicializarFormulario();
  }

  ngAfterViewInit(): void {
    this.formField._errorChildren = this.errors;
  }

  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }
}
