import { Component, inject } from '@angular/core';
import { TokenPayload } from '@core/models/services/autenticar/tokenPayload';
import { AutenticarService } from '@core/services/autenticar.service';
import { MenuLateralService } from '@core/services/menu-lateral.service';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrl: './cabecalho.component.scss'
})
export class CabecalhoComponent {
  protected menuLateralService = inject(MenuLateralService);
  protected authService = inject(AutenticarService);
  usuario?: TokenPayload = this.authService.tokenPayload();
}
