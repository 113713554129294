<input
  #input
  [multiple]="multiplo"
  type="file"
  [accept]="arquivosAceitos"
  class="c-input-arquivo_input"
  (change)="arquivoSelecionado($event)"
  (focus)="tocar()"
  (blur)="tocar()" />

<div class="c-input-arquivo_botao">
  @for (item of valor; track $index) {
    {{ item.nome }}
    @if ($index < valor.length - 1) {
      ,
    }
  } @empty {
    Selecione um arquivo
  }

  <button
    type="button"
    mat-mini-fab
    color="primary"
    (click)="input.click()"
    [disabled]="isDisabled">
    <mat-icon>attach_file</mat-icon>
  </button>
</div>
