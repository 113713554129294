<label class="c-app-form_label" for="codCargo">
  <span class="c-app-form_descricao">Cargo</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codCargo"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoCargo"
      placeholder="Selecione"
      (input)="filtrarCargo($event)"
      (focus)="filtrarCargo(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoCargo
      [displayWith]="exibirDadosCargo()">
      <mat-option>Selecione</mat-option>
      @for (cargo of cargosFiltrados; track cargo) {
        <mat-option [value]="cargo?.codCargo">
          {{ formatarSelectCargo(cargo) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
