<h1 class="c-nao-encontrado-component_titulo">
  Não encontramos a página solicitada
</h1>

<ng-lottie
  class="c-nao-encontrado-component_animacao"
  [options]="options"></ng-lottie>

<p class="c-nao-encontrado-component_descricao">
  Verifique o link digitado e tente novamente
</p>

<button
  class="c-nao-encontrado-component_botao"
  mat-raised-button
  color="primary"
  (click)="location.back()">
  Voltar
</button>
