<label class="c-app-form_label" for="codMedidor">
  <span class="c-app-form_descricao">Medidor</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codMedidor"
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoMedidor"
      placeholder="Selecione"
      (input)="filtrarMedidor($event)"
      (focus)="filtrarMedidor(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoMedidor
      [displayWith]="exibirDadosMedidor()">
      <mat-option>Selecione</mat-option>
      @for (medidor of medidoresFiltrados; track medidor) {
        <mat-option [value]="objetoComoValor ? medidor : medidor?.codRpMedidor">
          {{ formatarSelectMedidor(medidor) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
