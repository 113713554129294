<label
  class="c-app-form_label"
  for="codArea
">
  <span class="c-app-form_descricao">Area</span>
  <mat-form-field class="c-app-form_field">
    <input
      type="text"
      matInput
      name="codArea
      "
      [required]="invalido"
      [formControl]="control"
      [matAutocomplete]="autoArea"
      placeholder="Selecione"
      (input)="filtrarArea($event)"
      (focus)="filtrarArea(); tocar()"
      (blur)="tocar()" />
    <button type="button" matSuffix mat-icon-button (click)="limpar()">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      requireSelection
      #autoArea
      [displayWith]="exibirDadosArea()">
      <mat-option>Selecione</mat-option>
      @for (area of areasFiltradas; track area) {
        <mat-option [value]="area?.codArea">
          {{ formatarSelectArea(area) }}
        </mat-option>
      }
    </mat-autocomplete>
    <ng-content
      ngProjectAs="mat-error"
      select="mat-error, [matError]"></ng-content>
  </mat-form-field>
</label>
