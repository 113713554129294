import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  NavigationCancel,
  NavigationCancellationCode,
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Router
} from '@angular/router';
import { FUNCIONALIDADES_PERFIL } from '@core/constants/injectionTokens/funcionalidadesPerfil';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { ObterFuncionalidadesPerfilRequestBody } from '@core/models/services/perfil/obterFuncionalidadesPerfilRequestBody';
import { ObterFuncionalidadesPerfilResponseRegistro } from '@core/models/services/perfil/obterFuncionalidadesPerfilResponseRegistro';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';

import { AutenticarService } from './autenticar.service';

@Injectable({
  providedIn: 'root'
})
export class GestaoAcessoService implements OnDestroy {
  private subscription$ = new Subscription();
  private httpClient = inject(HttpClient);
  private authService = inject(AutenticarService);
  private router = inject(Router);
  private funcionalidadesPerfil = inject(FUNCIONALIDADES_PERFIL);
  private _funcionalidadesPerfil$ = new BehaviorSubject(
    new PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>()
  );
  private funcionalidadesPerfil$ = this._funcionalidadesPerfil$.asObservable();
  constructor() {
    this._funcionalidadesPerfil$.next(this.funcionalidadesPerfil);
    this.manipularFalhaAoNavegarPorFaltaDeAcesso();
    this.atualizarFuncionalidadesPerfil();
  }

  private manipularFalhaAoNavegarPorFaltaDeAcesso() {
    this.subscription$.add(
      this.router.events.subscribe(e => {
        if (
          !(
            e instanceof NavigationCancel &&
            e.code === NavigationCancellationCode.GuardRejected
          )
        ) {
          return;
        }

        this.router.navigate(['/']);
      })
    );
  }
  private obterFuncionalidadesPerfil(
    body: ObterFuncionalidadesPerfilRequestBody
  ): Observable<
    TechscanApiResponse<
      PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>
    >
  > {
    return this.httpClient.post<
      TechscanApiResponse<
        PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>
      >
    >('Perfil/ObterFuncionalidadesPerfil', body);
  }
  private atualizarFuncionalidadesPerfil() {
    this.subscription$.add(
      this.router.events.subscribe(e => {
        if (
          !(e instanceof NavigationStart) &&
          !(e instanceof NavigationEnd) &&
          !(e instanceof NavigationSkipped)
        ) {
          return;
        }

        const codPerfil = this.authService.tokenPayload()?.codPerfil;
        if (!codPerfil) {
          this._funcionalidadesPerfil$.next(
            new PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>()
          );
          return;
        }

        this.subscription$.add(
          this.obterFuncionalidadesPerfil({
            codPerfil: +codPerfil,
            paginar: false
          } as ObterFuncionalidadesPerfilRequestBody).subscribe(({ data }) => {
            if (!data) {
              this._funcionalidadesPerfil$.next(
                new PaginacaoResponseData<ObterFuncionalidadesPerfilResponseRegistro>()
              );
              return;
            }

            this._funcionalidadesPerfil$.next(data);
          })
        );
      })
    );
  }
  validaAcessos({
    key,
    value,
    permissao
  }: {
    key: keyof Pick<
      ObterFuncionalidadesPerfilResponseRegistro,
      'nomGrupo' | 'nomTag'
    >;
    value: ObterFuncionalidadesPerfilResponseRegistro[keyof Pick<
      ObterFuncionalidadesPerfilResponseRegistro,
      'nomGrupo' | 'nomTag'
    >];
    permissao?: 'HABILITADO' | 'CONSULTA' | 'DESABILITADO';
  }) {
    return this.funcionalidadesPerfil$.pipe(
      map(({ registros }) => {
        return registros.some(p => {
          if (permissao) {
            return p[key] === value && p.nomPermissao === permissao;
          }

          return p[key] === value && p.nomPermissao !== 'DESABILITADO';
        });
      })
    );
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
