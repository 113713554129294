import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Base64 } from '@core/models/base64';
import { PaginacaoResponseData } from '@core/models/services/paginacaoResponseData';
import { ExcluirLevantamentoRequestBody } from '@core/models/services/pesquisaProcedimento/excluirLevantamentoRequestBody';
import { ObterDocumentoAssinaturaRequestBody } from '@core/models/services/pesquisaProcedimento/obterDocumentoAssinaturaRequestBody';
import { ObterDocumentoAssinaturaResponseData } from '@core/models/services/pesquisaProcedimento/obterDocumentoAssinaturaResponseData';
import { ObterPesquisaProcedimentoRequestBody } from '@core/models/services/pesquisaProcedimento/obterPesquisaProcedimentoRequestBody';
import { ObterProcedimentoResponseRegistro } from '@core/models/services/pesquisaProcedimento/obterProcedimentoResponseRegistro';
import { ObterProtocoloAssinaturaRequestBody } from '@core/models/services/pesquisaProcedimento/obterProtocoloAssinaturaRequestBody';
import { ObterProtocoloAssinaturaResponseData } from '@core/models/services/pesquisaProcedimento/obterProtocoloAssinaturaResponseData';
import { ObterStatusAssinaturaRequestBody } from '@core/models/services/pesquisaProcedimento/obterStatusAssinaturaRequestBody';
import { ObterStatusAssinaturaResponseData } from '@core/models/services/pesquisaProcedimento/obterStatusAssinaturaResponseData';
import { AssinarLevantamentoRadioprotecaoRequestBody } from '@core/models/services/radioprotecao/assinarLevantamentoRadioprotecaoRequestBody';
import { CadastrarLevantamento7bRequestBody } from '@core/models/services/radioprotecao/cadastrarLevantamento7bRequestBody';
import { CadastrarLevantamentoARDRequestBody } from '@core/models/services/radioprotecao/cadastrarLevantamentoARDRequestBody';
import { CadastrarLevantamentoAreaRequestBody } from '@core/models/services/radioprotecao/cadastrarLevantamentoAreaRequestBody';
import { CadastrarRelatorioRadioprotecaoRequestBody } from '@core/models/services/radioprotecao/cadastrarRelatorioRadioprotecaoRequestBody';
import { ExcluirRelatorioRadioprotecaoRequestBody } from '@core/models/services/radioprotecao/excluirRelatorioRequestBody';
import { ObterArquivoDocumentoRequestBody } from '@core/models/services/radioprotecao/obterArquivoDocumentoRequestBody';
import { ObterDocumentoRadioprotecaoRequestBody } from '@core/models/services/radioprotecao/obterDocumentoRadioprotecaoRequestBody';
import { ObterImagemCroquiRequestBody } from '@core/models/services/radioprotecao/obterImagemCroquiRequestBody';
import { ObterPesquisaRelatorioRequestBody } from '@core/models/services/radioprotecao/obterPesquisaRelatorioRequestBody';
import { ObterRelatorioResponseRegistro } from '@core/models/services/radioprotecao/obterRelatorioResponseRegistro';
import { ObterRpAreaCroquiRequestBody } from '@core/models/services/radioprotecao/obterRpAreaCroquiRequestBody';
import { ObterRpAreaCroquiResponseRegistro } from '@core/models/services/radioprotecao/obterRpAreaCroquiResponseRegistro';
import { SalvarCabineExposicaoRequestBody } from '@core/models/services/radioprotecao/salvarCabineExposicaoRequestBody';
import { SalvarCabineIntegradaRequestBody } from '@core/models/services/radioprotecao/salvarCabineIntegradaRequestBody';
import { SalvarVerificacaoProcessoRequestBody } from '@core/models/services/radioprotecao/salvarVerificacaoProcessoRequestBody';
import { TechscanApiResponse } from '@core/models/services/techscanApiResponse';
import { ToastrService } from 'ngx-toastr';
import { first, Observable, of, switchMap } from 'rxjs';

import { AutenticarService } from './autenticar.service';
import { GestaoAcessoService } from './gestao-acesso.service';

@Injectable({
  providedIn: 'root'
})
export class RadioprotecaoService {
  private httpClient = inject(HttpClient);
  private autenticarService = inject(AutenticarService);
  private baseURL: string = 'RadioProtecao';
  private _gestaoAcessoService = inject(GestaoAcessoService);
  private _toastrService = inject(ToastrService);

  salvarCabineExposicao(
    body: SalvarCabineExposicaoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'levantamentoCabineExposicao',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/FecharCabineTaxaExposicao`,
            body
          );
        })
      );
  }

  salvarCabineIntegrada(
    body: SalvarCabineIntegradaRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'levantamentoCabineIntegrada',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/FecharCabineIntegrada`,
            body
          );
        })
      );
  }

  salvarVerificacaoProcesso(
    body: SalvarVerificacaoProcessoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'verificacaoProcesso',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/CadastrarVerificacaoProcesso`,
            body
          );
        })
      );
  }

  cadastrarLevantamento7b(
    body: CadastrarLevantamento7bRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'levantamento7B',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/CadastrarLevantamento7B`,
            body
          );
        })
      );
  }
  cadastrarLevantamentoARD(
    body: CadastrarLevantamentoARDRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'levantamentoARD',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/CadastrarLevantamentoARD`,
            body
          );
        })
      );
  }
  obterPesquisaProcedimento(
    body: ObterPesquisaProcedimentoRequestBody
  ): Observable<
    TechscanApiResponse<
      PaginacaoResponseData<ObterProcedimentoResponseRegistro>
    >
  > {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'visualizarTodosClientes',
        permissao: 'HABILITADO'
      })
      .pipe(
        first(),
        switchMap(habilitado => {
          if (
            !habilitado &&
            (!body?.codCliente || body.codCliente.length === 0)
          ) {
            body.codCliente =
              this.autenticarService.tokenPayload()?.codClientes;
          }
          return this.httpClient.post<
            TechscanApiResponse<
              PaginacaoResponseData<ObterProcedimentoResponseRegistro>
            >
          >(`${this.baseURL}/ObterProcedimentos`, body);
        })
      );
  }
  obterStatusAssinatura(
    body: ObterStatusAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterStatusAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterStatusAssinaturaResponseData>
    >(`${this.baseURL}/ObterStatusAssinatura`, body);
  }

  obterProtocoloAssinatura(
    body: ObterProtocoloAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterProtocoloAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterProtocoloAssinaturaResponseData>
    >(`${this.baseURL}/ObterProtocoloAssinatura`, body);
  }

  obterDocumentoAssinatura(
    body: ObterDocumentoAssinaturaRequestBody
  ): Observable<TechscanApiResponse<ObterDocumentoAssinaturaResponseData>> {
    return this.httpClient.post<
      TechscanApiResponse<ObterDocumentoAssinaturaResponseData>
    >(`${this.baseURL}/ObterDocumentoAssinatura`, body);
  }

  excluirLevantamento(
    body: ExcluirLevantamentoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'excluirProcedimento',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.delete<TechscanApiResponse>(
            `${this.baseURL}/ExcluirLevantamento`,
            { body }
          );
        })
      );
  }

  cadastrarLevantamentoArea(
    body: CadastrarLevantamentoAreaRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'levantamentoArea',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/CadastrarLevantamentoArea`,
            body
          );
        })
      );
  }

  obterPesquisaRelatorio(
    body: ObterPesquisaRelatorioRequestBody
  ): Observable<
    TechscanApiResponse<PaginacaoResponseData<ObterRelatorioResponseRegistro>>
  > {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'visualizarTodosClientes',
        permissao: 'HABILITADO'
      })
      .pipe(
        first(),
        switchMap(habilitado => {
          if (
            !habilitado &&
            (!body?.codCliente || body.codCliente.length === 0)
          ) {
            body.codCliente =
              this.autenticarService.tokenPayload()?.codClientes;
          }
          return this.httpClient.post<
            TechscanApiResponse<
              PaginacaoResponseData<ObterRelatorioResponseRegistro>
            >
          >(`${this.baseURL}/ObterRelatorioRadioProtecao`, body);
        })
      );
  }

  excluirRelatorioRadioprotecao(
    body: ExcluirRelatorioRadioprotecaoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'excluirRelatorioRadioprotecao',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.delete<TechscanApiResponse>(
            `${this.baseURL}/ExcluirRelatorioRadioprotecao`,
            { body }
          );
        })
      );
  }

  cadastrarRelatorioRadioprotecao(
    body: CadastrarRelatorioRadioprotecaoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'inserirRelatorioRadioprotecao',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/CadastrarRelatorioRadioprotecao`,
            body
          );
        })
      );
  }

  obterArquivoDocumento(
    body: ObterArquivoDocumentoRequestBody
  ): Observable<TechscanApiResponse<Base64>> {
    return this.httpClient.post<TechscanApiResponse<Base64>>(
      `${this.baseURL}/ObterArquivoDocumento`,
      body
    );
  }

  obterDocumentoRadioprotecao(
    body: ObterDocumentoRadioprotecaoRequestBody
  ): Observable<TechscanApiResponse<Base64>> {
    return this.httpClient.post<TechscanApiResponse<Base64>>(
      `${this.baseURL}/ObterDocumentoRadioprotecao`,
      body
    );
  }

  assinarLevantamentoRadioprotecao(
    body: AssinarLevantamentoRadioprotecaoRequestBody
  ): Observable<TechscanApiResponse> {
    return this._gestaoAcessoService
      .validaAcessos({
        key: 'nomTag',
        value: 'revisarProcedimento',
        permissao: 'CONSULTA'
      })
      .pipe(
        first(),
        switchMap(somenteConsulta => {
          if (somenteConsulta) {
            this._toastrService.success('Somente consulta');
            return of(new TechscanApiResponse());
          }
          return this.httpClient.post<TechscanApiResponse>(
            `${this.baseURL}/AssinarLevantamentoRadioprotecao`,
            body
          );
        })
      );
  }

  obterImagemCroqui(
    body: ObterImagemCroquiRequestBody
  ): Observable<TechscanApiResponse<Base64>> {
    return this.httpClient.post<TechscanApiResponse<Base64>>(
      `${this.baseURL}/ObterImagemCroqui`,
      body
    );
  }

  obterRpAreaCroqui(
    body: ObterRpAreaCroquiRequestBody
  ): Observable<
    TechscanApiResponse<
      PaginacaoResponseData<ObterRpAreaCroquiResponseRegistro>
    >
  > {
    return this.httpClient.post<
      TechscanApiResponse<
        PaginacaoResponseData<ObterRpAreaCroquiResponseRegistro>
      >
    >(`${this.baseURL}/ObterRpAreaCroqui`, body);
  }
}
